<template>
  <div class="profile p-6 rounded-lg bg-white">
    <p class="font-bold text-2xl text-center mb-6 mdmax:text-lg">Edit Profile</p>
    <div class="max-w-md mx-auto">
      <div class="flex justify-center mb-6">
        <div class="w-24 h-24 rounded-full bg-white relative">
          <input style="display: none" accept="image/jpeg, image/png" type="file" ref="photo" @change="selectImage($event)" />
          <User color="neutral-100" height="96" width="96" v-if="payloads.photo === '' || payloads.photo === undefined" />
          <div v-else class="w-full h-full rounded-full bg-no-repeat bg-cover profpic" :style="`--bgUrl: url(${userProfile.student.photo})`"></div>
          <div class="text-xs cursor-pointer right-0 bottom-0 p-1 absolute text-center bg-white border border-yellow text-yellow rounded-md font-semibold" @click="$refs.photo.click()">
            <Camera />
          </div>
        </div>
      </div>
      <p class="bg-yellow-100 text-sm text-yellow px-6 py-2 border mb-6 border-yellow rounded-lg text-center">Pastikan data yang tertera dibawah sesuai dengan KTP anda.</p>
      <div class="mb-4">
        <TextField type="text" borderEnabled placeholder="Input your name" label="Full Name" v-model="payloads.name" />
      </div>
      <div class="mb-4">
        <TextField type="text" disabled borderEnabled placeholder="Input your email" label="Email" v-model="payloads.email" />
      </div>
      <div class="mb-4">
        <TextField type="text" borderEnabled placeholder="Input your phone number" label="Phone Number" v-model="payloads.hp" />
      </div>
      <div class="mb-8">
        <label class="text-xs pl-3 text-left block mb-1">Short Description</label>
        <textarea class="h-40 text-sm border-grey-field border resize-none px-3 py-3 outline-none w-full rounded-lg" v-model="payloads.description" />
      </div>
      <div class="text-center">
        <Button buttonText="Save" @action="updateProfile('profile')" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast, formatDate } from '@/utils'
export default {
  components: {
    User: () => import(/* webpackChunkName: "icons" */ '@/components/icons/User'),
    Camera: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Camera'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data() {
    return {
      payloads: {
        photo: '',
        name: '',
        hp: '',
        email: '',
        description: ''
      }
    }
  },
  mounted() {
    this.payloads = {
      photo: this.userProfile?.student?.photo,
      name: this.userProfile?.name,
      hp: this.userProfile?.hp,
      email: this.userProfile?.email,
      description: this.userProfile?.student?.description
    }
  },
  computed: {
    ...mapGetters('prakerja', ['userProfile']),
    ...mapGetters('common', ['isMobile'])
  },
  watch: {
    userProfile(val) {
      this.payloads = {
        photo: val?.student?.photo,
        name: val?.name,
        hp: val?.hp,
        email: val?.email,
        description: val?.student?.description
      }
    }
  },
  methods: {
    ...mapActions('student', ['uploadImage', 'updateStudent']),
    ...mapActions('common', ['showLoading', 'hideLoading', 'setPhotoChanged']),
    ...mapActions('prakerja', ['getUserProfile']),
    ...mapActions('auth', ['getCurrentUser']),
    selectImage(event) {
      if (event.target.files[0]) {
        this.showLoading()
        const selectedFiles = event.target.files[0]
        const nameFile = event.target.files[0].name
        const fileImage = new FormData()
        fileImage.append('file', selectedFiles)
        fileImage.append('name', nameFile)

        this.uploadImage({ data: fileImage }).then(
          (response) => {
            this.payloads.photo = response.url
            localStorage.setItem('photo', response.url)
            this.updateProfile('photo')
            this.setPhotoChanged({ data: response.url })
          },
          () => {
            this.hideLoading()
          }
        )
      }
    },
    updateProfile(item) {
      this.showLoading()
      const payloads = {
        description: this.payloads.description,
        phoneNumber: this.payloads.hp,
        photo: this.payloads.photo,
        name: this.payloads.name
      }
      this.updateStudent({
        studentId: this.userProfile?.student?.id,
        payloads
      }).then(
        () => {
          let alert = ''
          switch (item) {
            case 'description':
              alert = 'Description'
              break
            case 'phone':
              alert = 'Phone Number'
              break
            case 'profile':
              alert = 'Profile'
              break
            default:
              alert = 'Profile Photo'
          }

          showVueToast(`${alert} is updated successfully!`, 'success', 3000)
          this.getUserProfile().then((res) => {
            localStorage.setItem('user_name', res.name)
            this.getCurrentUser()
            this.hideLoading()
            if (this.isMobile) {
              this.$router.push('/user/account')
            }
          })
        },
        () => {
          this.hideLoading()
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.profpic {
  background-image: var(--bgUrl);
}
</style>
